import React from 'react';
import PropTypes from 'prop-types';

import withT from 'ComponentLibrary/hocs/withT';

const NoContentGuard = ({ hasContent, children, t }) => {
  if (hasContent) {
    return children;
  }
  return <p className="no-content">{t('no_content')}</p>;
};

NoContentGuard.propTypes = {
  hasContent: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired,
};

export default withT(NoContentGuard);
