import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import withScrollToBottomTracking from '../hocs/withScrollToBottomTracking';
import LoadMore from './load-more';

class LoadMoreScrollToBottomTracker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadCount: 0,
    };

    this.onScrolledToBottom = this.onScrolledToBottom.bind(this);
  }

  componentDidMount() {
    const { waitUntilMoved } = this.props;
    if (waitUntilMoved === false) {
      this.props.onScrolledToBottom();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isLoading && this.props.isLoading) {
      this.setState({ loadCount: this.state.loadCount + 1 });
    }
  }

  onScrolledToBottom() {
    // Throughout the app, <LoadMore> is configured to trigger
    // very early (essentially immediately) in scrolling.
    // This ensures that we don't consider loading the next
    // page of results to be equivalent to "scrolling to bottom"
    // unless we actually have scrolled one page's worth of
    // items.
    if (this.state.loadCount > 0) {
      this.props.onScrolledToBottom();
    }
  }

  render() {
    return (
      <LoadMore {...this.props} onScrolledToBottom={this.onScrolledToBottom} />
    );
  }
}

LoadMoreScrollToBottomTracker.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  waitUntilMoved: PropTypes.bool,
  onScrolledToBottom: PropTypes.func.isRequired,
};

export default withScrollToBottomTracking(LoadMoreScrollToBottomTracker);
