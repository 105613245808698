import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import comscorePageView from 'Utils/comscore-page-view';
import { noOpFn } from 'Utils/no-op-fn';

// Components
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import LoadingSpinner from 'ComponentLibrary/icons/loading-spinner';
import Visible from './visible';
import withT from 'ComponentLibrary/hocs/withT';

export const STYLE_VARIATIONS = {
  NONE: 'none',
  SECTION_TILES: 'section-tiles',
};

class LoadMore extends React.Component {
  constructor(props) {
    super(props);
    this.loadingNext = false;
    this.loadingPromise = null;
    this.loadNext = this.loadNext.bind(this);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  async loadNext() {
    if (this.loadingNext || this.unmounted) {
      return;
    }
    this.props.onScrolledToBottom();
    this.loadingNext = true;
    this.loadingPromise = this.props.loadNext();
    await this.loadingPromise;

    this.loadingNext = false;
    this.forceUpdate();
    comscorePageView();
  }

  render() {
    const {
      isLoading,
      infinite,
      immediateInfinite,
      bufferBottom,
      styleVariation,
      waitUntilMoved,
      t,
    } = this.props;
    const classes = classNames({
      'load-more': true,
      'load-more--section-tiles':
        styleVariation === STYLE_VARIATIONS.SECTION_TILES,
    });
    const label =
      styleVariation === STYLE_VARIATIONS.SECTION_TILES
        ? t('see_more')
        : t('load_more');

    return (
      <div key="load-more" className={classes}>
        {immediateInfinite || (this.loadingPromise && infinite) ? (
          <React.Fragment>
            <Visible
              waitUntilMoved={waitUntilMoved}
              bufferBottom={bufferBottom}
            >
              {(isVisible) => isVisible && this.loadNext()}
            </Visible>
            {this.loadingNext ? <LoadingSpinner /> : null}
          </React.Fragment>
        ) : (
          <Button
            name="section-load-more"
            styleVariation={StyleVariations.SECONDARY}
            label={label}
            onClick={this.loadNext}
            loading={isLoading}
          />
        )}
      </div>
    );
  }
}

LoadMore.propTypes = {
  loadNext: PropTypes.func.isRequired,
  infinite: PropTypes.bool,
  immediateInfinite: PropTypes.bool,
  isLoading: PropTypes.bool,
  styleVariation: PropTypes.oneOf(Object.values(STYLE_VARIATIONS)),
  bufferBottom: PropTypes.number,
  onScrolledToBottom: PropTypes.func,
  waitUntilMoved: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

LoadMore.defaultProps = {
  isLoading: false,
  infinite: true,
  immediateInfinite: false,
  bufferBottom: 7500,
  styleVariation: STYLE_VARIATIONS.NONE,
  waitUntilMoved: true,
  onScrolledToBottom: noOpFn,
};

export default withT(LoadMore);
