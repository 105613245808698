import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { COLORS, SURFACE_COLORS } from 'Style/colors';
import { UI_TEXT_TYPES } from 'Style/typography';

// Utils
import WindowResize from 'Utils/window-resize';
import HocUtil from 'Utils/hoc-util';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';

const ContentVeil = styled.div({
  height: '460px',
  background: COLORS.fadeEffect,
  position: 'absolute',
  left: '0',
  width: '100%',
  cursor: 'pointer',
  pointerEvents: 'none',
});

const ContentFooter = styled.div({
  backgroundColor: SURFACE_COLORS.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: SPACING.BASE4X,
  paddingTop: SPACING.BASE4X,
  cursor: 'pointer',
});

const SeeMoreBannerText = styled.div({
  ...UI_TEXT_TYPES.SUPPORTING_EMPHASIS,
  color: COLORS.red,
  cursor: 'pointer',
  marginBottom: SPACING.LARGE,
  '&:hover': {
    color: `darken(${COLORS.red}, 10%)`,
    textDecoration: 'underline',
  },
});

// Minor gap appearing with the calculated offset. Adding 2 to cover any potential rounding errors
const ROUNDING_FUDGE_VALUE = 2;

function withContentVeil(Component) {
  class WrappedComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        showVeil: !props.isAuthenticated && !props.isAmp,
      };

      this.renderContentVeil = this.renderContentVeil.bind(this);
      this.loadMoreContents = this.loadMoreContents.bind(this);
      this.setPositionTopValue = this.setPositionTopValue.bind(this);
      this.resizer = new WindowResize(this.setPositionTopValue);
      this.fadeEffectRef = null;
      this.containerRef = null;
    }

    componentDidMount() {
      this.setPositionTopValue();
      this.resizer.subscribe();
    }

    componentWillUnmount() {
      this.resizer.unsubscribe();
    }

    loadMoreContents() {
      this.setState({ showVeil: false });
    }

    /**
     * Refreshes the position calculation of the veil.
     * Should on render when content is done loading.
     */
    setPositionTopValue() {
      if (!this.fadeEffectRef || !this.containerRef) {
        return;
      }

      const value =
        this.containerRef.offsetTop -
        this.fadeEffectRef.offsetHeight +
        ROUNDING_FUDGE_VALUE;
      const displayValue =
        value <= ROUNDING_FUDGE_VALUE || this.state.hideVeil ? 'none' : 'block';

      const inlineStyleObject = { top: `${value}px`, display: displayValue };
      this.inlineStyle = inlineStyleObject;
    }

    /**
     *
     * @param {*} bannerComponent: Banner component to display below the veil
     * @returns
     */
    renderContentVeil(bannerComponent) {
      const { isAuthenticated, t } = this.props;

      if (isAuthenticated) {
        return null;
      }
      return (
        <React.Fragment>
          <ContentVeil
            style={this.inlineStyle}
            ref={(element) => {
              if (!element) {
                return;
              }
              this.fadeEffectRef = element;
            }}
          />
          <ContentFooter
            ref={(element) => {
              if (!element) {
                return;
              }
              this.containerRef = element;
            }}
            onClick={this.loadMoreContents}
          >
            <SeeMoreBannerText>{t('see_more_stories')}</SeeMoreBannerText>
            {bannerComponent}
          </ContentFooter>
        </React.Fragment>
      );
    }

    render() {
      return (
        <Component
          {...this.props}
          setPositionTopValue={this.setPositionTopValue}
          renderContentVeil={this.renderContentVeil}
          veilShown={this.state.showVeil}
        />
      );
    }
  }

  WrappedComponent.displayName = HocUtil.displayName(Component);
  WrappedComponent.propTypes = {
    section: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    isAmp: PropTypes.bool,
    t: PropTypes.func,
  };
  return connector(connectAmp, connectAuthentication)(withT(WrappedComponent));
}

export default withContentVeil;
