import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

// Utils
import { USAGE_DISPLAY_STYLES } from 'Utils/analytics/usage';
import { SPACING } from 'Style/spacing';
// Components
import GetAppButton from 'Webapp/shared/app/containers/get-app-button';
import SignupButton from 'Webapp/shared/app/containers/signup-button';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectAuthentication from 'Webapp/shared/app/connectors/connectAuthentication';
import {
  StyleModifiers,
  StyleVariations,
} from 'Webapp/shared/app/components/button';
import withT from 'ComponentLibrary/hocs/withT';

const StyledCTABannerRedesigned = styled.section({
  padding: `${SPACING.BASE4X}`,
  backgroundColor: 'var(--color--surface-secondary)',
  textAlign: 'center',
  '.cta-banner-redesigned__title': {
    marginBottom: `${SPACING.MEDIUM}`,
  },
  '.cta-banner-redesigned__sub-title': {
    marginBottom: `${SPACING.XLARGE}`,
  },
});
function CTABannerRedesigned({ isLargeScreen, isAuthenticated, t }) {
  if (isAuthenticated) {
    return null;
  }
  const styleModifiers = [StyleModifiers.FAT];

  // TODO: Add final copy to i18n

  return (
    <StyledCTABannerRedesigned role="banner" className="cta-banner-redesigned">
      <hgroup>
        <h2 className="cta-banner-redesigned__title ui-text--title">
          {t('cta_join_community')}
        </h2>
        <h3 className="cta-banner-redesigned__sub-title ui-subheading--large-dimmed">
          {t('cta_discover_collect')}
        </h3>
      </hgroup>
      {isLargeScreen ? (
        <SignupButton
          name="sign-up-banner"
          label={t('sign_up')}
          styleVariation={StyleVariations.PRIMARY}
          styleModifier={styleModifiers}
          displayStyle={USAGE_DISPLAY_STYLES.CTA_BANNER}
        />
      ) : (
        <GetAppButton name="get-app-banner" styleModifier={styleModifiers} />
      )}
    </StyledCTABannerRedesigned>
  );
}

CTABannerRedesigned.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  isLargeScreen: PropTypes.bool.isRequired,
};

export default connector(
  connectResponsive,
  connectAuthentication,
)(withT(CTABannerRedesigned));
